import React, { Component } from "react";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import { useTheme } from '@material-ui/core/styles';

import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderRightLinks from "components/Header/HeaderRightLinks.js";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks.js";

import './AboutPage.css';
import styles from "assets/jss/material-kit-react/views/componentsSections/basicsStyle.js";
const useStyles = makeStyles(styles);
const dashboardRoutes = [];

class AboutPage extends Component {
    // const classes = useStyles();

    constructor(props) {
        super(props);
        this.state = {
            OStype: ''
        };
    }

    componentDidMount(){
        window.scrollTo(0, 0);
        this.setState({
            OStype: this.getMobileOperatingSystem()
        });
    };

    getMobileOperatingSystem = () => {
        var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    
        // Windows Phone must come first because its UA also contains "Android"
        if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
        }
    
        if (/android/i.test(userAgent)) {
          return "Android";
        }
    
        // iOS detection from
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
        }
    
        return "unknown";
      }

    render(){
        const { classes } = this.props;
        return (
            <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
                <Header
                    color="white"
                    routes={dashboardRoutes}
                    rightLinks={<HeaderRightLinks />}
                    leftLinks={<HeaderLeftLinks />}
                    fixed
                    changeColorOnScroll={{
                        height: 400,
                        color: "white"
                    }}
                //{...rest}
                />
                <div className={classes.container} style={{ marginTop: '100px', minHeight: '60vh' }}>
                <img src={require('assets/img/FF_map_only.png')}
            style={{ position: 'fixed', zIndex: '-10', width: '100%', bottom: '0', opacity: '3%' }}
          />
                    <h2 style={{ fontWeight: '600', color: '#00784A', borderBottom: '3px solid #FECE00' }}>About Us</h2>
                    <span id='aboutSpan' style={{ display: 'flex', flexDirection: 'column'}}>
                    <span className='rowSmCol' style={{width: '100%', display: 'flex', marginBottom: '3rem', textAlign: 'justify'}}> 
                    {/* <img src={require('assets/img/wallet.png')} /> */}
                    <span id='about1'>
                    <p>
                        Finding Faber is the latest innovation of John Patrick Publishing Company’s commitment to superior customer service. At JPPC, our advertisers are partners in sustaining strong communities.  With over 11,000 active ads in our weekly print publications, we are giving our advertisers a new footprint in the digital space. Finding Faber gives interactive exposure to a large client base.  Our local businesses and professional practices are directly and quickly accessible and located on the Finding Faber app, and geolocated on the map along with pertinent information. Special promotions are amplified and Finding Faber delivers the thousands of local parishioners, along with potentially the broader population, to businesses’ doorsteps.  Wherever you are, Finding Faber is there to connect businesses and professional practices to local patrons.
                    </p>
                    <p>
                        Like our namesake, Peter Faber, we believe that business serves the most important role in communities. Faber, the undeniable patron saint of small businesses, lived in the 1500s and was an advocate of the entrepreneurial spirit of the small business person. He knew the power of business transactions beginning at the most local level. When he saw citizens living in communities, he felt strongly that the best way for everyone to succeed, was commerce accessible to all. When businesses are successful, entire communities prosper. We couldn’t agree more. This is why we created Finding Faber and why we go above and beyond to make sure the businesses are connecting to the communities and all are benefiting.
                    </p>
                    
                    </span>
                    <span id='about2' style={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <img src={require('assets/img/FF phone image only.png')} style={{display: 'inline', width: '100%'}}/>
                    <p>
                        As a small business ourselves, we have spent almost 30 years connecting small businesses to consumers in their communities. We know entrepreneurs and business owners are not focused solely on making themselves wealthy. Rather, they wake up every morning and go to sleep each night feeling blessed to be able to offer a service or product, employ local talent, help others support their families, and contribute to their communities. At JPPC and Finding Faber, we fully support all business and professional practices and feel that we are successful only when they are successful.
                    </p>
                    <p>
                        For more information about Finding Faber or John Patrick Publishing Company, go to <a href='https://www.jppc.net/' target='_blank' style={{textDecoration: 'underline'}}>www.jppc.net</a>. To place an ad in a weekly bulletin in any of our parishes, click on Ad Sales.
                    </p>
                    </span>
                    </span>
                    <span id='appPreview' className='rowSmCol' style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}}>
                        <img src={require('assets/img/findingfaberAppCropped.png')} alt='screenshot of Finding Faber app on map view' style={{
                            border: '5px solid black', borderRadius: '15px', maxWidth: '250px'
                        }} />
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly'}}>
                        <div style={{display: 'flex', flexDirection:'column', alignItems:'center'}}>
                        <h3>Get the app!</h3>
                        {/* link to app store here: */}
                        <a href={ this.state.OStype === 'iOS' ? 'https://apps.apple.com/us/app/id1528978308' : 'https://play.google.com/store/apps/details?id=com.findingfaber' } target='_blank'>
                        <img src={require('assets/img/Finding Faber_new_prom.jpg')} alt='qr placeholder' style={{ maxWidth: '400px', maxHeight: '300px', padding: '1rem', border: '2px dashed #FECE00'}} />
                        </a>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems:'center'}}>
                            <h5>Follow us!</h5>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                <a target='_blank' href='https://www.facebook.com/finding.faber'><img style={{maxWidth: '59px'}} src={require('assets/img/facebook.webp')} alt='facebook icon'/></a>
                                <a target='_blank' href='https://twitter.com/FaberFinding'><img style={{maxWidth: '50px'}} src={require('assets/img/twitter.webp')} alt='twitter icon' /></a>
                            </div>
                        </div>
                        </div>
                    </span>
                    </span>
                </div>
                <Footer />
            </div>
        );
    }   
}

export default withStyles(styles, { withTheme: true })(AboutPage);