import React, { useEffect } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
import { Redirect, Link } from 'react-router-dom';
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderRightLinks from "components/Header/HeaderRightLinks.js";
import HeaderLeftLinks from "components/Header/HeaderLeftLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomInput from "components/CustomInput/CustomInput.js";

import TextField from '@material-ui/core/Textfield';
import Divider from '@material-ui/core/Divider';
import FilterListIcon from '@material-ui/icons/FilterList';
import SearchIcon from '@material-ui/icons/Search';
import MapIcon from '@material-ui/icons/Map';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import LanguageIcon from '@material-ui/icons/Language';

import styles from "assets/jss/material-kit-react/views/landingPage.js";

import './LandingPage.css';

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import ConnectSection from "./Sections/ConnectSection.js";
import Paper from '@material-ui/core/Paper';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';

import Radio from '@material-ui/core/Radio';
// @material-ui/SnackBar
import Snackbar from '@material-ui/core/Snackbar';

import findingFaberLogo from '../../assets/img/FindingFaber-Logo/FFapp_icon only.png';

import api from '../../utils/api';

const dashboardRoutes = [];

const useStyles = makeStyles(styles);
const usePaperStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 200,
  },
  merchantRoot: {
    minWidth: 375,
    maxWidth: 350,
  },
  media: {
    height: 150
  },
}));


export default function LandingPage(props) {
  const classes = useStyles();
  const cardClasses = usePaperStyles();
  const { ...rest } = props;


  const [firstFilter, setFirstFilter] = React.useState(null);
  const [secondFilter, setSecondFilter] = React.useState(null);
  const [isLocationGranted, setLocationPermission] = React.useState(null);
  const [nearbyPromList, setNearbyPromList] = React.useState([]);
  const [nearbyMerchantList, setNearbyMerchantList] = React.useState([]);
  const [categoryList, setCategoryList] = React.useState([]);
  const [isCategoryReady, setIsCategoryReady] = React.useState(false);
  const [isRedirectSearch, setIsRedirectSearch] = React.useState(false);
  const [searchK, setSearchK] = React.useState('');
  const [searchCity, setSearchCity] = React.useState('');
  const [searchMK, setSearchMK] = React.useState('');
  const [userZip, setUserZip] = React.useState('');
  const [searchType, setSearchType] = React.useState('M');
  const [selectedMerchant, setSelectedMerchant] = React.useState(null);
  const [redirectToMerchant, setRedirectToMerchant] = React.useState(false);
  const [redirectToPromotion, setRedirectToPromotion] = React.useState(false);
  const [selectedPromotion, setSelectedPromotion] = React.useState(false);
  const [snackMsg, setSnackMsg] = React.useState(false);
  const [shouldSnackBarOpen, setShouldSnackBarOpen] = React.useState(false);
  const [selectedCat, setSelectedCat] = React.useState(0);
  const [OStype, setOStype] = React.useState('');


  const handleMSearchInputChange = (e) => {
    setSearchMK(e.currentTarget.value);
  }

  const handleSearchInputChange = (e) => {
    setSearchK(e.currentTarget.value);
  }

  const checkSearchType = (k) => {
    return /^\d{5}(-\d{4})?$/.test(k);
  }

  // const handleCitySearch = () => {
  //   api.getLatLong(searchCity).then(res => {
  //     var d = {
  //       lat: res.results[0].geometry.location.lat,
  //       long: res.results[0].geometry.location.lng
  //     }
  //     api.getAddress(d).then(dt => {
  //       var zip = extractData('zip', dt.results[0]);
  //       var searchObj = {};
  //       var type = searchType;
  //       var isZip = checkSearchType(zip);
  //       if (isZip) {
  //         //If Searchtype is Promotion
  //         if (type == "P") {
  //           searchObj = {
  //             SourceZipCode: zip,
  //             MaxDistance: 5,
  //             CategoryID: selectedCat,
  //             SubCategoryID: 0,
  //             searchCity: searchCity
  //           }
  //         } else {
  //           searchObj = {
  //             SourceZipCode: zip,
  //             MaxDistance: 5,
  //             CategoryID: selectedCat,
  //             SubCategoryID: 0,
  //             MerchantName: '',
  //             searchCity: searchCity
  //           }
  //         }
  //       } else {
  //         if (type == "P") {
  //           searchObj = {
  //             SourceZipCode: '08601',
  //             MaxDistance: 5,
  //             CategoryID: selectedCat,
  //             SubCategoryID: 0,
  //             searchCity: searchCity
  //           }
  //         } else {
  //           searchObj = {
  //             SourceZipCode: '08601',
  //             MaxDistance: 5,
  //             CategoryID: selectedCat,
  //             SubCategoryID: 0,
  //             MerchantName: '',
  //             searchCity: searchCity
  //           }
  //         }
  //       }

  //       props.setSearchObj(searchObj);
  //       setIsRedirectSearch(true);
  //       // Redirect to result view page
  //     })
  //   });
  // }


  const submitSearch = async (cat = null) => {
    var type = searchType;
    var isZip;
    var isBoth;
    var zipToUse = null;
    var searchObj;
    var searchedKey;

    //Use user zip if location was granted unless user enter a location
    if (isLocationGranted) {
      zipToUse = userZip;
      searchedKey = userZip;
    }
    // Check If user entered a zipcode
    if (searchCity) {
      searchedKey = searchCity;
      isZip = checkSearchType(searchCity);
      if (isZip) {
        zipToUse = searchCity;
      } else {
        zipToUse = await api.getLatLong(searchCity).then(async res => {
          var d = {
            lat: res.results[0].geometry.location.lat,
            long: res.results[0].geometry.location.lng
          }
          return await api.getAddress(d).then(dt => {
            return extractData('zip', dt.results[0]);
          })
        })
      }
    }

    if (!zipToUse) {
      openSnackBar("Please Provide a zip code or a location")
      // setIsLoading(false);
      return;
    }

    //Check If user entered both location and merchant name
    if (searchK && searchCity) {
      isBoth = true;
    }

    if (searchK) {
      searchedKey = searchedKey + ", " + searchK
    }

    // setSearchedKey(searchedKey);
    //If Searchtype is Promotion
    if (type == 'P') {
      searchObj = {
        SourceZipCode: zipToUse,
        MaxDistance: 5,
        CategoryID: cat || selectedCat,
        SubCategoryID: 0,
      }
    } else {
      searchObj = {
        SourceZipCode: zipToUse,
        MaxDistance: 5,
        CategoryID: cat || selectedCat,
        SubCategoryID: 0,
        MerchantName: searchK ? searchK : ''
      }
    }
    console.log("searchObj: ", searchObj);
    props.setSearchObj(searchObj);
    setIsRedirectSearch(true);
  }


  // const handleSearch = (cat = null) => {
  //   console.log("called")
  //   if (searchCity && !searchK) {
  //     handleCitySearch();
  //     return;
  //   }
  //   var searchObj = {};
  //   var type = searchType
  //   console.log("searchK: ", searchK);
  //   console.log('userZip: ', userZip);
  //   //If Searchtype is Promotion
  //   if (type == "P") {
  //     if (!searchK) {
  //       if (userZip) {
  //         searchObj = {
  //           SourceZipCode: userZip,
  //           MaxDistance: 5,
  //           categoryID: cat || selectedCat,
  //           SubCategoryID: 0,
  //         }
  //         console.log("searchObj: ", searchObj);
  //         props.setSearchObj(searchObj);
  //         setIsRedirectSearch(true);
  //         return;
  //       } else {
  //         openSnackBar("Please Provide a zip code")
  //         return;
  //       }
  //     }
  //     //If user location is On with promo search
  //     if (isLocationGranted) {
  //       var isZip = checkSearchType(searchK);
  //       //If user enter a zip Code with promo search
  //       if (isZip) {
  //         searchObj = {
  //           SourceZipCode: searchK,
  //           MaxDistance: 5,
  //           categoryID: cat || selectedCat,
  //           SubCategoryID: 0,
  //         }
  //       } else { //If user enter a merchant name with promo search with granted location
  //         searchObj = {
  //           SourceZipCode: userZip,
  //           MaxDistance: 5,
  //           categoryID: cat || selectedCat,
  //           SubCategoryID: 0,
  //           MerchantName: searchK
  //         }
  //       }
  //     } else {  //If no location granted
  //       var isZip = checkSearchType(searchK);
  //       //If user enter a zip Code with no location granted
  //       if (isZip) {
  //         searchObj = {
  //           SourceZipCode: searchK,
  //           MaxDistance: 5,
  //           categoryID: cat || selectedCat,
  //           SubCategoryID: 0,
  //         }
  //       } else {
  //         // Show zip code error msg
  //       }
  //     }
  //   } else { // If search type is Merchant
  //     var isZip = checkSearchType(searchK);
  //     if (!searchK && !isLocationGranted) {
  //       openSnackBar('Please enter City or Zip code');
  //       return;
  //     }
  //     //If user location is On
  //     if (isLocationGranted) {
  //       //If user enter zip code for merchant search
  //       if (isZip) {
  //         searchObj = {
  //           SourceZipCode: searchK,
  //           MaxDistance: 5,
  //           categoryID: cat || selectedCat,
  //           SubCategoryID: 0,
  //           MerchantName: ''
  //         }
  //       } else {  //If user enter Merchant name for merchant search with granted location
  //         searchObj = {
  //           SourceZipCode: userZip,
  //           MaxDistance: 5,
  //           categoryID: cat || selectedCat,
  //           SubCategoryID: 0,
  //           MerchantName: searchK
  //         }
  //       }
  //     } else { //If location was declined with merchant search
  //       //If user enter zip code for merchant search
  //       if (isZip) {
  //         searchObj = {
  //           SourceZipCode: searchK,
  //           MaxDistance: 5,
  //           categoryID: cat || selectedCat,
  //           SubCategoryID: 0,
  //           MerchantName: ''
  //         }
  //       } else {  //If user enter Merchant name for merchant search with declined location
  //         // Show error zip code error
  //       }
  //     }
  //   }
  //   props.setSearchObj(searchObj);
  //   setIsRedirectSearch(true);
  //   // Redirect to result view page
  // }

  const searchCat = (cat) => {
    console.log("clicked");
    setSelectedCat(cat.categoryID);
    submitSearch(cat.categoryID);
  }

  const viewAll = (type) => {
    var searchObj;
    if (type == 'M') {
      searchObj = {
        SourceZipCode: userZip,
        MaxDistance: 5,
        CategoryID: selectedCat,
        SubCategoryID: 0,
        MerchantName: ''
      }
    } else {
      searchObj = {
        SourceZipCode: userZip,
        MaxDistance: 5,
        CategoryID: selectedCat,
        SubCategoryID: 0
      }
    }
    props.setSearchObj(searchObj);
    setIsRedirectSearch(true);
  }

  useEffect(() => {
    api.getCategories().then(res => {
      if (res.data) {
        setCategoryList(res.data);
        setIsCategoryReady(true);
      }
    });

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(getPosition, handleLocationDecline);
      setLocationPermission(true);
    } else {
      handleLocationDecline()
    }

    setOStype(getMobileOperatingSystem());
  }, []);

  const handleLocationDecline = () => {
    props.setUserZip('08601');
    setUserZip('08601');
    setInitList('08601');
    setLocationPermission(false);
  }

  function getPosition(position) {
    api.getAddress({ lat: position.coords.latitude, long: position.coords.longitude }).then(res => {
      console.log('res: ', res);
      var isZip = false;
      if(res?.results){
        var zip = extractData('zip', res.results[0]);
        isZip = checkSearchType(zip);
      }
      if (isZip) {
        props.setUserZip(zip);
        console.log("UserZip setting: ", zip);
        setUserZip(zip);
        setInitList(zip);
      } else {
        props.setUserZip('08601');
        setUserZip('08601');
        setInitList('08601');
        setLocationPermission(false);
        openSnackBar("An unexpected error occur while trying to detect your location. You can still search by entering your zip code!")
      }
    })
  }

  const openSnackBar = (msg) => {
    setSnackMsg(msg);
    setShouldSnackBarOpen(true);
  }

  const handleSnackBarClose = () => {
    setShouldSnackBarOpen(false);
    setSnackMsg('');
  }

  const setInitList = (zip) => {

    api.searchMerchant({
      SourceZipCode: zip.toString(),
      MaxDistance: 5,
      CategoryID: selectedCat,
      SubCategoryID: 0,
      MerchantName: ''
    }).then(res => {
      var displayables = [];
      if (res.data) {
        res.data.forEach(el => {
          if (el.website || el.phone || el.merchantEmail || el.address1 || el.city || el.state || el.zip) {
            displayables.push(el)
          }
        });
      }
      setNearbyMerchantList(displayables);
    });

    api.searchPromotion({
      data: {
        SourceZipCode: zip.toString(),
        MaxDistance: 5,
        CategoryID: selectedCat,
        SubCategoryID: 0,
      },
      idToken: null
    }).then(res => {
      if (res.data) {
        setNearbyPromList(res.data);
      }
    });
  }

  const extractData = (type, googleDt) => {
    for (var i = 0; i < googleDt.address_components.length; i++) {
      if (type == 'street_address') {
        var r = null;
        for (var j = 0; j < googleDt.address_components[i].types.length; j++) {
          if (googleDt.address_components[i].types[j].indexOf('street_number') > -1 || googleDt.address_components[i].types[j].indexOf('route') > -1) {
            r += googleDt.address_components[i].long_name + ' ';
          }
        }
        return r;
      }
      if (type == 'town') {
        for (var j = 0; j < googleDt.address_components[i].types.length; j++) {
          if (googleDt.address_components[i].types[j].indexOf('locality') > -1) {
            return googleDt.address_components[i].long_name;
          }
        }
      }
      if (type == 'county') {
        for (var j = 0; j < googleDt.address_components[i].types.length; j++) {
          if (googleDt.address_components[i].types[j].indexOf('administrative_area_level_2') > -1) {
            return googleDt.address_components[i].long_name;
          }
        }
      }
      if (type == 'state') {
        for (var j = 0; j < googleDt.address_components[i].types.length; j++) {
          if (googleDt.address_components[i].types[j].indexOf('administrative_area_level_1') > -1) {
            return googleDt.address_components[i].long_name;
          }
        }
      }
      if (type == 'country') {
        for (var j = 0; j < googleDt.address_components[i].types.length; j++) {
          if (googleDt.address_components[i].types[j].indexOf('country') > -1) {
            return googleDt.address_components[i].long_name;
          }
        }
      }
      if (type == 'zip') {
        for (var j = 0; j < googleDt.address_components[i].types.length; j++) {
          if (googleDt.address_components[i].types[j].indexOf('postal_code') > -1) {
            return googleDt.address_components[i].long_name;
          }
        }
      }
      if (type == 'full_address') {
        return googleDt.formatted_address;
      }
    }
  }

  let scrollTo = (type) => {
    switch (type) {
      case 'connect':
        document.getElementById("connect").scrollIntoView({ behavior: 'smooth', block: 'center' });
        break;
      default: break;
    }
  }

  const shrinkDescription = (text) => {
    if (text) {
      var textArr = text.split(" ");
      if (textArr.length > 20) {
        var res = '';
        textArr.forEach(element => {
          res += " " + element;
        });
        return res + "...";
      } else {
        return text;
      }
    } else {
      return "Click to read more!"
    }
  }

  const showMerchantInfos = (m) => {
    setSelectedMerchant('/search/' + m.merchantID + "/0");
    setRedirectToMerchant(true);
  }

  const showPromotionInfos = (p) => {
    setSelectedPromotion('/search/' + p.merchantID + "/" + p.merchantPromotionID);
    setRedirectToPromotion(true);
  }

  const handleSearchCityChange = (e) => {
    setSearchCity(e.currentTarget.value);
  }

  const launchMap = (address) => {
    if (address != null && address != '') {

      var Stype = getMobileOperatingSystem();

      if (Stype == "iOS") {
        var link = "http://maps.apple.com/?q=" + address;
        window.open(link, '_blank');
      } else {
        var link = "https://maps.google.com/?q=" + address;
        window.open(link, '_blank');
      }
    }
  }

  const getMobileOperatingSystem = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
      return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
      return "Android";
    }

    // iOS detection
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "iOS";
    }

    return "unknown";
  }

  const dial = (phoneNumber) => {
    var link = "tel:" + phoneNumber;
    window.location.href = link;
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      submitSearch();
    }
  }

  return (
    <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      {isRedirectSearch ? <Redirect push to='/search/all/0' /> : null}
      {redirectToMerchant ? <Redirect push to={selectedMerchant} /> : null}
      {redirectToPromotion ? <Redirect push to={selectedPromotion} /> : null}
      <Header
        color="white"
        routes={dashboardRoutes}
        rightLinks={<HeaderRightLinks />}
        leftLinks={<HeaderLeftLinks />}
        fixed
        changeColorOnScroll={{
          height: 400,
          color: "white"
        }}
        {...rest}
      />
      {/* <Parallax> */}
      <div className={classes.container}>
        {/* <GridContainer style={{height: '100%'}}> */}
        <img src={require('assets/img/FF_map_only.png')}
          style={{ position: 'fixed', zIndex: '-10', width: '100%', bottom: '0', opacity: '5%' }}
        />

        <span style={{ display: 'flex', flexDirection: 'column', width: '100%', height: 'fit-content', paddingTop: '150px' }}>

          <div className='sm2'>
            <div id='appAd' className={classes.rowSmallCol} style={{ display: 'flex' }}>

              <div className='half' style={{display: 'flex'}}>
              <img  style={{width: '95%'}} src={require('assets/img/Finding Faber_new_prom.jpg')} alt='app promotional image' />
                {/* <img  style={{width: '50%'}} src={require('assets/img/ffforqr.png')} alt='app promotional image' />
                <div style={{width: '50%'}}>
                  <a style={{width: '45%'}} href='https://play.google.com/store/apps/details?id=com.findingfaber' target='_blank'>
                    <img style={{width: '45%'}} src={require('assets/img/ffforgoogle.png')} alt='qr code for app on google play' />
                  </a>
                  <img style={{width: '10%'}} src={require('assets/img/ffor.png')} alt='or' />
                  <a style={{width: '45%'}} href='https://apps.apple.com/us/app/id1528978308' target='_blank'>
                    <img style={{width: '45%'}} src={require('assets/img/ffforapple.png')} alt='qr code for app on app store' />
                  </a>
                </div> */}
              </div>
              <img className='half' src={require('assets/img/FF map and phone.png')} alt='map with Finding Faber logo on phone' />
            </div>
            <div id='aboutBlurb' style={{ display: 'flex', flexDirection: 'column', margin: '-40px 0 15px', color: '#252326' }}>
              <h3>What is <span className='ff-finding'>Finding</span><span className='ff-faber'>Faber</span><span className='ff-tm'>&trade;</span>?</h3>
              <p style={{ margin: '0 10px 15px' }}>John Patrick Publishing welcomes you to <span className='ff-finding'>Finding</span><span className='ff-faber'>Faber</span><span className='ff-tm'>&trade;</span>, the easy to use app connecting local businesses and the community. Download the <span className='ff-finding'>Finding</span><span className='ff-faber'>Faber</span><span className='ff-tm'>&trade;</span> app <img src={require('assets/img/favicon.png')} width='20px' alt='Finding Faber app logo' />, and easily search by Promotions, Business Category, or your location to instantly find specials, discounts and featured information offered by merchants and professionals within your search criteria. </p>
              <div className={classes.rowMdCol} style={{ display: 'flex' }}>
                <span style={{ display: 'flex', flexDirection: 'row', margin: '0 10px' }}>
                  <img style={{ marginRight: '10px', height: '75px' }} src={require('assets/img/FindingFaber-Logo/pin-drop-1.png')} alt='Finding Faber pin drop' />
                  <p>As a business owner, <span className='ff-finding'>Finding</span><span className='ff-faber'>Faber</span><span className='ff-tm'>&trade;</span> allows you to share real-time specials, promotions and information with your community and friends. Simply sign in from your computer or tablet, and add whatever promotion you would like to offer to the public. You are able to change your offers &amp; content weekly, daily - even hourly! </p>
                </span>
                <span style={{ display: 'flex', flexDirection: 'row', margin: '0 10px' }}>
                  <img style={{ marginRight: '10px', height: '75px' }} src={require('assets/img/FindingFaber-Logo/pin-drop-1.png')} alt='Finding Faber pin drop' />
                  <p>As a community member using the <span className='ff-finding'>Finding</span><span className='ff-faber'>Faber</span><span className='ff-tm'>&trade;</span> app, you are able to instantly locate the promotions you choose with pinpoint mapping as well as receive instant notifications of your choosing posted by your favorite local businesses. Never miss a discount again from favorite business!</p>
                </span>
              </div>
            </div>
          </div>

          {/*  search span: */}
          <div id='action-area' className='sm1'>
            <a href={OStype === 'iOS' ? 'https://apps.apple.com/us/app/id1528978308' : 'https://play.google.com/store/apps/details?id=com.findingfaber'} target='_blank'>
              <img style={{ width: '100%' }} className={classes.showSmall} src={require('assets/img/fake QR codes.png')} alt='app promotional image' />
            </a>
            <span className={classes.rowMlCol} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <span id='searchRadio' style={{ paddingTop: '20px', paddingRight: '25px', display: 'flex', flexDirection: 'column' }}>
                <Typography className={classes.showSmall} variant="body2" color="textSecondary" component="p">
                  Find...
                </Typography>
                <span style={{ display: 'flex', flexDirection: 'row' }}>

                  <span style={{ display: 'flex', marginLeft: '15px', fontSize: '14px', alignItems: 'flex-end', justifyContent: 'center' }}>
                    <Radio
                      checked={searchType === 'M'}
                      onChange={() => setSearchType('M')}
                      value="M"
                      color="primary"
                      name="merchant-search-type"
                      inputProps={{ 'aria-label': 'A' }}
                      style={{ padding: '1px 7px' }}
                    />
                    <span onClick={() => setSearchType('M')} style={{ color: 'black', cursor: 'pointer' }}><span className={classes.hideSmall}>Find </span>Businesses</span>
                  </span>
                  <span style={{ display: 'flex', flexDirection: 'row', fontSize: '14px', alignItems: 'flex-end', justifyContent: 'center' }}>
                    <Radio
                      checked={searchType === 'P'}
                      onChange={() => setSearchType('P')}
                      value="P"
                      color="primary"
                      name="promotion-search-type"
                      inputProps={{ 'aria-label': 'A' }}
                      style={{ padding: '1px 7px' }}
                    />
                    <span onClick={() => setSearchType('P')} style={{ color: 'black', cursor: 'pointer' }}><span className={classes.hideSmall}>Find </span>Promotions</span>
                  </span>
                </span>
              </span>
              <span className={classes.rowSmallCol} id='searchArea' style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end' }}>
                <span style={{ display: 'flex', justifyContent: 'flexEnd', width: '100%' }}>
                  <TextField label={"City, State/Zip"}
                    value={searchCity} onChange={(e) => handleSearchCityChange(e)} //onFocus={() => setSearchK('')}
                    onKeyPress={handleKeyPress}
                    style={{ padding: '0 3px', width: '50%' }} />
                  <span style={{ margin: '0 25px', paddingTop: '15px', color: '#252326', alignItems: 'flex-end' }} className={classes.hideM}>And/Or</span>

                  <TextField label={"Business name"}
                    value={searchK} onChange={(e) => handleSearchInputChange(e)} //onFocus={() => setSearchCity('')} 
                    onKeyPress={handleKeyPress}
                    style={{ padding: '0 3px', width: '50%' }} />
                </span>
                <Button color="warning" onClick={() => submitSearch()} style={{ fontWeight: 'bold', color: '#252326', height: '40px', margin: '20px 0 0 0' }}><SearchIcon className={classes.inputIconsColor} />Search</Button>
              </span>
            </span>
            <Divider style={{ marginTop: '30px' }} />
            <h4 style={{ color: 'black', marginTop: '20px' }}><span className={classes.hideSmall}>Explore our </span>Categories</h4>
            {/* <Divider /> */}
            {isCategoryReady ? (
              <span className="categories-sug">
                {categoryList.categoryModelList.map((cat, index) => {
                  switch (cat.categoryName) {
                    case "Automotive": {
                      return (
                        <Paper className="cat-list-card" onClick={() => searchCat(cat)} key='Automotive'>
                          <img src={require('assets/img/automotive.png')} width="85px" height="85px" style={{ borderRadius: '50%', margin: '10px auto' }} alt="" />
                          <span style={{ width: '100%', textAlign: 'center' }}>Automotive</span>
                        </Paper>
                      )
                      // };
                      break;
                    }
                    case "Contractor Services": {
                      return (
                        <Paper className="cat-list-card" onClick={() => searchCat(cat)} key='Contractor'>
                          <img src={require('assets/img/contractore.jpg')} width="125px" height="85px" style={{ borderRadius: '50%', margin: '10px auto' }} id='w90' alt="" />
                          <span style={{ width: '100%', textAlign: 'center' }}>Contractor Services</span>
                        </Paper>
                      )
                      // };
                      break;
                    }
                    case "Life Events": {
                      return (
                        <Paper className="cat-list-card" onClick={() => searchCat(cat)} key='Life'>
                          <img src={require('assets/img/events.png')} width="85px" height="85px" style={{ borderRadius: '50%', margin: '10px auto' }} alt="" />
                          <span style={{ width: '100%', textAlign: 'center' }}>Life Events</span>
                        </Paper>
                      )
                      // };
                      break;
                    }
                    case "Food & Beverage": {
                      return (
                        <Paper className="cat-list-card" onClick={() => searchCat(cat)} key='Food'>
                          <img src={require('assets/img/foodBeverage.png')} width="120px" height="95px" style={{ borderRadius: '50%', margin: '10px auto' }} alt="" id='w85' />
                          <span style={{ width: '100%', textAlign: 'center' }}>Food/Beverage</span>
                        </Paper>
                      )
                      // };
                      break;
                    }
                    case "Health & Wellness": {
                      return (
                        <Paper className="cat-list-card" onClick={() => searchCat(cat)} key='Health'>
                          <img src={require('assets/img/health.png')} width="125px" height="95px" style={{ borderRadius: '50%', margin: '10px auto' }} alt="" id='w85' />
                          <span style={{ width: '100%', textAlign: 'center' }}>Health/Wellness</span>
                        </Paper>
                      )
                      // };
                      break;
                    }
                    case "Home & Garden": {
                      return (
                        <Paper className="cat-list-card" onClick={() => searchCat(cat)} key='Home'>
                          <img src={require('assets/img/Home garden.jpg')} width="110px" height="110px" style={{ borderRadius: '50%', margin: '10px auto' }} alt="" />
                          <span style={{ width: '100%', textAlign: 'center' }}>Home/Garden</span>
                        </Paper>
                      )
                      // };
                      break;
                    }
                    case "Leisure & Travel": {
                      return (
                        <Paper className="cat-list-card" onClick={() => searchCat(cat)} key="Travel">
                          <img src={require('assets/img/Travel.png')} width="85px" height="85px" style={{ borderRadius: '50%', margin: '10px auto' }} alt="" />
                          <span style={{ width: '100%', textAlign: 'center' }}>Leisure/Travel</span>
                        </Paper>
                      )
                      // };
                      break;
                    }
                    case "Retail/Other": {
                      return (
                        <Paper className="cat-list-card" onClick={() => searchCat(cat)} key='Retail'>
                          <img src={require('assets/img/retail.png')} width="85px" height="85px" style={{ borderRadius: '50%', margin: '10px auto' }} alt="" />
                          <span style={{ width: '100%', textAlign: 'center' }}>Retail/Other</span>
                        </Paper>
                      )
                      // };
                      break;
                    }
                    case "Professional Services": {
                      return (
                        <Paper className="cat-list-card" onClick={() => searchCat(cat)} key="service">
                          <img src={require('assets/img/professional.png')} width="90px" height="85px" style={{ borderRadius: '50%', margin: '10px auto' }} alt="" id='w65' />
                          <span style={{ width: '100%', textAlign: 'center' }}>Professional Services</span>
                        </Paper>
                      )
                      // };
                      break;
                    }
                  }
                })}
              </span>
            ) : null}
            {nearbyPromList.length > 0 ? (<span>
              <Divider style={{ marginTop: '40px' }} />

              <span style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <h4 style={{ color: 'black', marginTop: '20px' }}>{isLocationGranted ? "Local Deals:" : "You may be interested:"}</h4>
              </span>
              <span style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                <Button className='sm2' size="sm" color="primary" style={{ maxHeight: '30px', margin: '15px 0px' }} onClick={() => viewAll('P')}>View All &nbsp; <span className={classes.hideSmall}> Local Deals</span></Button>
              </span>
              <span className="landing-search-result scrollContainer">
                {nearbyPromList.map((promo, i) => {
                  var desc;
                  if (promo.description2 && promo.description3) {
                    desc = promo.description2 + " " + promo.description3;
                  } else {
                    desc = '';
                  }
                  return (
                    <span style={{ padding: '7px' }} key={'promo-' + i} onClick={() => showPromotionInfos(promo)}>
                      <Card className={cardClasses.root}>
                        <CardActionArea>
                          <CardMedia
                            className={cardClasses.media}
                            image={promo.coupon || (promo.logoFile || require("assets/img/FindingFaber-Logo/FFapp_icon only.png"))}
                            title={promo.merchantName}
                          />
                          <CardContent>
                            <Typography gutterBottom variant="body1" component="span">
                              {promo.description1}
                            </Typography>
                            <Typography id='readMore' variant="body2" color="textSecondary" component="p">
                              {shrinkDescription(desc)}
                            </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions className={classes.hideSmall}>
                          <Button fullWidth color="primary">
                            View Promo
                            </Button>
                        </CardActions>
                      </Card>
                    </span>
                  )
                })}
              </span>
            </span>) : null}
            <Divider style={{ marginTop: '40px' }} />
            {nearbyMerchantList.length ? (<span>
              <span style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                <h4 style={{ color: 'black', marginTop: '20px' }}>{isLocationGranted ? "Local Businesses:" : "You may Also be interested:"}</h4>
              </span>
              <div>
                <span style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Button className='sm2' size="sm" color="primary" style={{ maxHeight: '30px', margin: '15px 0px' }} onClick={() => viewAll('M')}>View All &nbsp;<span className={classes.hideSmall}> Local Businesses</span></Button>
                </span>
              </div>
              <span className="merch-search-result scrollContainer" style={{ flexWrap: 'nowrap', justifyContent: 'initial' }}>
                {nearbyMerchantList.map((merch, j) => {
                  return (
                    <span style={{ padding: '7px' }} key={'merchCard-' + j} onClick={() => showMerchantInfos(merch)}>
                      <Card className={cardClasses.merchantRoot} style={{ maxWidth: 'unset', minWidth: 'unset', height: '100%' }}>
                        <CardActionArea style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'flex-start', height: '100%' }}>
                          {/* <span style={{ display: 'flex', flexDirection: 'row'}}> */}
                          {/* <span> */}
                          <CardMedia
                            className={`${cardClasses.media} merchantImg`}
                            image={merch.logoFile || require("assets/img/FindingFaber-Logo/FFapp_icon only.png")}
                            title={merch.merchantName}
                            style={{ width: '220px', height: '220px' }}
                          />
                          {/* <Button size="sm" color="primary">
                                  View
                                </Button>
                              </span> */}
                          <CardContent
                            className='ellipsesWrapper'
                            style={{ padding: '12px', justifyContent: 'center', width: '220px' }}
                          // style={{ width: '200px', height: '120px' }}
                          >
                            <Typography gutterBottom className='ellipses3' variant="body1" component="span">
                              {merch.merchantName}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                              <span style={{ display: 'flex', flexDirection: 'column', overflowWrap: 'break-word' }}>
                                {(merch.address1 || merch.city || merch.state || merch.zip) ? (
                                  <span onClick={() => launchMap(merch.address1 + ", " + merch.city + ", " + merch.state + " " + merch.zip)} className='ellipsesWrapper' style={{ width: '100%', display: 'flex', flexDirection: 'row', overflowWrap: 'break-word', alignItems: 'center' }}>
                                    <MapIcon style={{ marginRight: '10px', verticalAlign: 'middle', fontSize: '15px', color: '#0476B5' }} />
                                    <span className='ellipses2' style={{ overflowWrap: 'break-word' }}>{merch.address1} {merch.city} {merch.state} {merch.zip}</span>
                                  </span>
                                ) : null}
                                {/* {merch.phone ? (
                                      <span style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
                                        <PhoneIcon style={{ marginRight: '10px', verticalAlign: 'middle', fontSize: '15px', color: '#0476B5'}} />
                                        <span>{merch.phone}</span>
                                      </span>
                                    ) : null}
                                    {merch.merchantEmail ? (
                                      <span style={{ width: '100%', display: 'flex', flexDirection: 'row'}}>
                                        <EmailIcon style={{ marginRight: '10px', verticalAlign: 'middle', fontSize: '15px', color: '#0476B5'}} />
                                        <span>{merch.merchantEmail}</span>
                                      </span>
                                    ) : null} */}
                                {merch.website ? (
                                  <span className='ellipsesWrapper' style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <LanguageIcon style={{ marginRight: '10px', verticalAlign: 'middle', fontSize: '15px', color: '#0476B5' }} />
                                    <span className='ellipses1'><a href={merch.website} style={{ textDecoration: 'none' }}>{merch.website}</a></span>
                                  </span>
                                ) : null}
                              </span>
                            </Typography>
                          </CardContent>
                          {/* </span> */}
                        </CardActionArea>
                        {/* <CardActions>
                          </CardActions> */}
                      </Card>
                    </span>
                  )
                })}
              </span>
            </span>) : null}
          </div>

        </span>

      </div>

      <Footer />

      {shouldSnackBarOpen ? (
        <span style={{ zIndex: 999999 }}>
          <Snackbar
            // anchorOrigin={{ "bottom", "center" }}
            open={shouldSnackBarOpen}
            onClose={handleSnackBarClose}
            message={snackMsg}
            key="key"
          />
        </span>
      ) : null}
    </div>
  );
}
